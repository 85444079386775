<template>
  <a-layout id="login-layout-body" class="layout">
    <div class="login-header">
      <div class="login-header-title">快安科技</div>
      <div class="login-header-subtitle">——核心管理系统</div>
    </div>
    <a-layout-content id="login-layout-centent">
      <a-card id="login-form" hoverable :bodyStyle="{ width: '80%' }">
        <a-card-meta>
          <div slot="title">
            <a-row style="font-size: 23px; color: #066da5;">
              账号登陆
            </a-row>
          </div>
          <template slot="description">
            <a-row>
              <mu-form ref="loginForm" :model="loginForm" style="text-align: left;">
                <mu-form-item prop="Username" label="用户名" :rules="loginFormRules.Username" @keyup.enter.native="clickSignIn">
                  <mu-text-field v-model="loginForm.Username" :disabled="apiAwait" label-float ></mu-text-field>
                </mu-form-item>
                <mu-form-item prop="Password" label="密 码" :rules="loginFormRules.Password">
                  <mu-text-field v-model="loginForm.Password" :disabled="apiAwait" label-float :type="visibility ? 'text' : 'Password'" @keyup.enter.native="clickSignIn">
                    <a-icon v-if="!visibility" slot="append" type="eye-invisible" @click="() => (visibility = !visibility)" />
                    <a-icon v-else slot="append" type="eye" @click="() => (visibility = !visibility)" />
                  </mu-text-field>
                </mu-form-item>
              </mu-form>
            </a-row>
            <a-row>
              <a-button-group>
                <a-button type="primary" :loading="apiAwait" @click="clickSignIn">立即登录</a-button>
                <a-button type="danger" @click="toKM">快马驿站<a-icon type="right" /> </a-button>
              </a-button-group>
            </a-row>
          </template>
        </a-card-meta>
      </a-card>
      <div class="footer">
        ©2020 Created by 快安科技
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import licia from 'licia'
import { Account } from '@/api'
export default {
  data () {
    return {
      apiAwait: false,
      loginForm: {
        Username: '',
        Password: '',
      },
      loginFormRules: {
        Username: [
          { validate: (val) => !!val, message: '必须填写用户名'},
          { validate: (val) => val.length >= 2, message: '用户名长度大于2'}
        ],
        Password: [
          { validate: (val) => !!val, message: '必须填写密码'},
          { validate: (val) => val.length >= 6 && val.length <= 32, message: '密码长度大于6小于32'}
        ]
      },
      visibility: false
    }
  },
  methods: {
    async clickSignIn () {
      if (!(await this.$refs['loginForm'].validate())) {
        this.$message.error('请检查用户名和密码是否填写正确')
        return
      }
      this.apiAwait = true
      const loginRes = await this.$axios.post(`${Account}/Login`, this.loginForm).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.apiAwait = false
      if (!loginRes.status) {
        this.$message.error(loginRes.message)
        return
      }
      const { token, role, userInfo, action, partitionId } = loginRes.data
      if (licia.isEmpty(role)) {
        this.$message.error('您没有任何权限')
        return
      }
      localStorage.setItem('token', token)
      this.$root.$data.privateState.token = localStorage.getItem('token')
      this.$root.$data.privateState.userInfo = userInfo
      this.$root.$data.privateState.role = role
      this.$root.$data.privateState.partitionId = partitionId
      if (action) {
        this.$root.$data.privateState.action = action
      } else {
        this.$root.$data.privateState.action = []
      }
      
      this.$router.replace({ name: 'Dashboard' })
    },
    toKM() {
      window.location.href = 'https://km.kuaian.vip'
    }
  }
}
</script>
<style>
.login-header {
  position: relative;
  top: 130px;
}
.login-header-title {
  font-size: 33px;
  color: rgba(0,0,0,.85);
}
.login-header-subtitle {
  font-size: 20px;
  color: rgba(0,0,0,.85);
}
#login-layout-body {
  min-height: 100%;
  text-align: center;
  background: #f0f2f5;
}
#login-layout-centent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f2f5;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
}
#login-form {
    height: 360px;
    max-width: 400px;
    width: 90%;
    background-color: rgba(255, 255, 255);
    align-items: center;
    justify-content: center;
    display: flex;
}
.footer {
  text-align: center;
  width: 100%;
  position:absolute;
  bottom:0;
  color: rgba(0,0,0,.85);
}
</style>
